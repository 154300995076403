<template>
  <section id="dashboard-ecommerce">
    
    <div>
     
        <!-- <div class="text-center"><h3>Linkedin Post Tracker</h3></div> -->

        <b-card>
          <div>
            <b-row>
              <b-col md="4" xl="4" xs="4" class="mb-1">
                <b-form-group
                  label="Start Date"
                  label-for="start-date"
                >
                  <flat-pickr
                    v-model="postDate"
                    class="form-control"
                    :config="{ enableTime: false,dateFormat: 'Y-m-d'}"
                  />
                  <small v-if="!postDate" class="text-danger">Post Date is Mandatory</small>
                </b-form-group>
              </b-col>
              <b-col md="4" xl="4" xs="4" class="mb-1">
                <b-form-group
                  label=""
                  label-for="">
                  <b-button variant="outline-primary" width="100%" @click="getLinkedInPost(postDate)" style="margin-top: 20px">Get Report</b-button>
                </b-form-group>
              </b-col>
            </b-row>
            <div v-if="loading">
                <div class="text-center">
                  <b-spinner variant="primary" label="Text Centered" />
                </div>
            </div>
            <div v-if="!loading && items ">
              <div class="mb-1">
                <b-button
                  size="sm"
                  class="mr-1"
                  variant="outline-primary"
                  @click="selectAllRows"
                >
                  Select all
                </b-button>
                <b-button
                  size="sm"
                  class="mr-1"
                  variant="outline-primary"
                  @click="clearSelected"
                >
                  Clear selected
                </b-button>
                <b-button
                  size="sm"
                  class="mr-1"
                  variant="outline-success"
                  @click="markPostSubmittedAsYES()"
                >
                  Mark as YES
                </b-button>
                <b-button
                  size="sm"
                  class="mr-1"
                  variant="outline-danger"
                  @click="markPostSubmittedAsNO()"
                >
                  Mark as NO
                </b-button>
                <b-button
                  size="sm"
                  class="mr-1"
                  variant="outline-secondary"
                  @click="refreshData()"
                >
                 Refresh
                </b-button>
              </div>
              <b-table
                ref="selectableTable"
                selectable
                select-mode="multi"
                :fields="fields"
                :items="items"
                responsive="sm"
                @row-selected="onRowSelected"
              >
                <!-- Example scoped slot for select state illustrative purposes -->
                <template #cell(selected)="{ rowSelected }">
                  <template v-if="rowSelected">
                    <i class="feather icon-disc primary" />
                  </template>

                  <template v-else>
                    <i class="feather icon-circle" />
                  </template>
                </template>

                <template #cell(empid)="data">
                  <div class="text-nowrap">
                    {{ data.item.recruiter_empid }}
                  </div>
                </template>

                <template #cell(name)="data">
                  <div class="text-nowrap">
                    {{ data.item.recruiter_name }}
                  </div>
                </template>
                <template #cell(post_submitted)="data">
                  <div class="text-nowrap">
                    {{ data.item.post_submitted }}
                  </div>
                </template>
                <template #cell(actions)="data">
                  <div class="demo-inline-spacing">
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      @click="clickedYES"
                      style="margin-top: 0 !important; padding: 0.3rem 0.6rem;"
                    >
                      YES
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-danger"
                      @click="clickedNO"
                      style="margin-top: 0 !important; padding: 0.3rem 0.6rem;"
                    >
                      NO
                    </b-button>
                    <b-button
                      size="sm"
                      class="mr-1"
                      variant="primary"
                      @click="updateSinglePost(data.item)"
                      style="margin-top: 0 !important;"
                    >
                      Update
                    </b-button>
                  </div>
                </template>
              </b-table>

             
              
            </div>
            
            <div v-if="!loading && !items ">
              {{  reportStatus }}
            </div>   
          </div>
        </b-card>  
      </div>
  </section>
</template>

<script>
import { BRow, BCol, BCard, BButton, BFormGroup, BSpinner, BTable } from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

import LinkedinPostService from '@/services/linkedinpost-service'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BFormGroup,
    BSpinner,
    flatPickr,
    vSelect,
    BTable
    
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      postDate: '',
      //endDate: '',
      tracker: '',
      items: [],
      loading: false,
      downloadReport: '',
      filename: '',
      reportStatus: '',
      fields: ['selected',  { key: 'recruiter_empid', label: 'Emp Id',sortable: true, }, { key: 'recruiter_name', label: 'Recruiter Name',sortable: true, }, { key: 'post_submitted', label: 'Post Submitted' }, { key: 'post_date', label: 'Post Date' }, {key: 'actions'}],
      selected: [],
      postSubmitted: '',
      reportStatus: '',
      accessCheck: false,
      userEmpId: null
      //loading: false
    }
  },
  created() {
    
    const userRole = JSON.parse(localStorage.getItem('userData')).roles[0]
    this.userEmpId = JSON.parse(localStorage.getItem('userData')).emp_id

    this.accessCheck = userRole === 'ACCOUNT-MANAGER' || userRole === 'TALENT-ADVISOR' || userRole === 'TALENT-ADVISOR-SOURCER' ? false : true
    console.log(this.accessCheck)
    if(this.accessCheck === true) {
      //this.postDate = moment().locale("en").add(-1, 'days').format("YYYY-MM-DD")
      this.postDate = moment().locale("en").format("YYYY-MM-DD")
      this.loading = true
      this.getLinkedInPost(this.postDate)
      //this.refreshData()
    } else {
      //return next({ name: 'misc-not-authorized' })
      this.$router.push({ name: "misc-not-authorized" });
    }
    
    

    //this.getReport();
  },
  methods: {
    getLinkedInPost(postDate) {
      console.log('called, post date : ', postDate, this.loading)
      this.loading = true
      console.log('called ', postDate)
      LinkedinPostService.getAllPost(postDate).then(res => {
      if(res.status === 'OK') {
        console.log(res)
        this.items = res.data
        this.loading = false
        console.log(this.items)
      } else if ((res.status === 401 ) || (res.status === 403 )) {
        window.sessionStorage.setItem('lastPage', window.location.href.split('/#')[1])
        this.reportStatus = 'Session Expired... Redirecting to Login Page'
        localStorage.clear();
        this.$router.push({ name: "auth-login" });
      } else if ((res.status === 400 ) ) {
        this.reportStatus = 'Some Error Occurred, Please Try after sometime. If Same Issue occurs multiple times, Kindly contact IT admin.'
        this.loading = false
        this.compiledData = null
      }
      else if ((res.status === 500 ) ) {
        this.reportStatus = 'ERROR: ' + res.data.message + 'Kindly contact IT admin.'
        this.loading = false
        this.compiledData = null
      }
    })
    },
    onRowSelected(items) {
      this.selected = items
      console.log(this.selected)
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
      console.log(this.selected)
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },
    clickedYES() {
      this.postSubmitted = true
      console.log(this.postSubmitted)
    },
    clickedNO() {
      this.postSubmitted = false
      console.log(this.postSubmitted)
    },
    updateSinglePost(item) {
      let data = {
        empid: item.recruiter_empid,
        name: item.recruiter_name,
        mgr_id: item.mgr_id,
        post_date: this.postDate,
        post_submitted: this.postSubmitted,
        updated_by: this.userEmpId
      }
      console.log('linkedinpost data: ',data)
      this.loading = true
      this.updatePost(data)
      this.loading = false
    },
    updatePost(data) {
      console.log('update item', data)
      LinkedinPostService.updatePost(data).then(res => {
        console.log(res)
        if(res.status === 'OK') {
          console.log('updated')
          this.getLinkedInPost()
          
        } else if ((res.status === 401 ) || (res.status === 403 )) {
          window.sessionStorage.setItem('lastPage', window.location.href.split('/#')[1])
          this.reportStatus = 'Session Expired... Redirecting to Login Page'
          localStorage.clear();
          this.$router.push({ name: "auth-login" });
        } else if ((res.status === 400 ) ) {
          console.log('in 400 status')
          //this.reportStatus = 'Some Error Occurred, Please Try after sometime. If Same Issue occurs multiple times, Kindly contact IT admin.'
          this.loading = true
          LinkedinPostService.addPost(data).then(res => {
              console.log(res)
              if(res.status === 'OK') {
                console.log('created')
                this.getLinkedInPost()
                //this.loading = false
              } else if ((res.status === 401 ) || (res.status === 403 )) {
                window.sessionStorage.setItem('lastPage', window.location.href.split('/#')[1])
                this.reportStatus = 'Session Expired... Redirecting to Login Page'
                localStorage.clear();
                this.$router.push({ name: "auth-login" });
              } else if ((res.status === 400 ) ) {
                
                //this.reportStatus = 'Some Error Occurred, Please Try after sometime. If Same Issue occurs multiple times, Kindly contact IT admin.'
                this.loading = false
                
                //this.compiledData = null
              }
              else if ((res.status === 500 ) ) {
                this.reportStatus = 'ERROR: ' + res.data.message + 'Kindly contact IT admin.'
                this.loading = false
                //this.compiledData = null
              }
            })
          //this.compiledData = null
        }
        else if ((res.status === 500 ) ) {
          this.reportStatus = 'ERROR: ' + res.data.message + 'Kindly contact IT admin.'
          this.loading = false
          //this.compiledData = null
        }
      })
    },
    getLinkedInPost() {
      LinkedinPostService.getAllPost(this.postDate).then(res => {
        if(res.status === 'OK') {
          this.items = res.data
          this.loading = false
        } else if ((res.status === 401 ) || (res.status === 403 )) {
          window.sessionStorage.setItem('lastPage', window.location.href.split('/#')[1])
          this.reportStatus = 'Session Expired... Redirecting to Login Page'
          localStorage.clear();
          this.$router.push({ name: "auth-login" });
        } else if ((res.status === 400 ) ) {
          this.reportStatus = 'Some Error Occurred, Please Try after sometime. If Same Issue occurs multiple times, Kindly contact IT admin.'
          this.loading = false
          this.compiledData = null
        }
        else if ((res.status === 500 ) ) {
          this.reportStatus = 'ERROR: ' + res.data.message + 'Kindly contact IT admin.'
          this.loading = false
          this.compiledData = null
        }
      })
    },
    markPostSubmittedAsYES() {
      this.loading = true
      this.postSubmitted = true
      for (var i = 0; i < this.selected.length; i++) {

        let data = {
          empid: this.selected[i].recruiter_empid,
          name: this.selected[i].recruiter_name,
          post_date: this.postDate,
          post_submitted: this.postSubmitted,
          mgr_id: this.selected[i].mgr_id,
          updated_by: this.userEmpId
        }
        console.log(data, this.selected[i])
        this.updatePost(data);
      }
      this.getLinkedInPost()
      this.loading = false
    },
    markPostSubmittedAsNO() {
      this.loading = true
      this.postSubmitted = false
      for (var i = 0; i < this.selected.length; i++) {
        
        let data = {
          empid: this.selected[i].recruiter_empid,
          name: this.selected[i].recruiter_name,
          post_date: this.postDate,
          post_submitted: this.postSubmitted,
          mgr_id: this.selected[i].mgr_id,
          updated_by: this.userEmpId
        }
        this.updatePost(data);
      
      }
      this.getLinkedInPost()
      this.loading = false
    },
    refreshData() {
      this.loading = true
      this.getLinkedInPost()
      this.loading = false
    }
  },
  
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';

</style>
<style scoped>

</style>
